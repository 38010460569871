
.new_footer_area {
  background: #fbfbfd;
}


.new_footer_top {
  padding: 60px 0px 260px;
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-footer {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
animation: fadeIn 1s ease forwards;
}

.row {
display: flex;
justify-content: space-around;
/* flex-wrap: wrap; */
max-width: 10000px; /* Set a max-width to prevent content from stretching too much */
}

.col-lg-3,
.col-md-6 {
flex: 1 1 25%; /* Adjust as needed for the number of columns and desired layout */
margin: 10px; /* Add some spacing between columns */
}

/* Keyframe animation for fadeIn */
@keyframes fadeIn {
from {
  opacity: 0;
  transform: translateY(-50px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

/* Media query for mobile responsiveness */
@media (max-width: 820px) {
.col-lg-3,
.col-md-6 {
  flex: 1 1 100%; /* Full width on mobile */
}

/* .container-footer{
  display: inline;
}
.row{
  display: grid;
  grid-template-rows: 2;
  justify-content: center;
  align-items: center;
  width: 50%;
} */
.new_footer_top {
  display: grid;
  grid-template-rows: auto auto; /* Two rows */
  justify-items: center;
  align-items: center;
  background-color: ghostwhite;
}

.col-lg-3,
.col-md-6 {
  flex: 1 0 100%; /* Full width on mobile */
  margin-bottom: 20px; /* Add some space between rows */
}

.footer_bg {
  display: block; /* Show the footer background on mobile */
}

.row {
  display: grid;
  margin: 10px 30px;
  grid-template-columns:  auto auto;
  grid-gap: 10px; 
}

.footer_bg_one {
  background-color: #f0f0f0; /* Example background color */
}

.footer_bg_two {
  background-color: #e0e0e0; /* Example background color */
}
.teamso{
  display: none;
}
.f_social_icon{
  margin-top: 30px;
  display: grid;
  justify-items: end;
  grid-template-columns: repeat(2 ,1fr);
  grid-template-rows: repeat(2 , 1fr);

}
.f_social_icon a{
  margin: 5px;
}
}


.new_footer_area .footer_bottom {
  padding-top: 5px;
  padding-bottom: 50px;
}
.footer_bottom {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
}
.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
  margin-bottom: 20px;
}
.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}
.btn_get_two:hover {
  background: transparent;
  color: #5e2ced;
}
.btn_get:hover {
  color: #fff;
  background: #6754e2;
  border-color: #6754e2;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.memail{
  background: #5e2ced;
  border: none;
  outline: none;
  border-radius: 40px;
  padding: 4px 8px;
  color: white    ;
}
a:hover, a:focus, .btn:hover, .btn:focus, button:hover, button:focus {
  text-decoration: none;
  outline: none;
}



.new_footer_top .f_widget.about-widget .f_list li a:hover {
  color: #100fb1;
}
.new_footer_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px;
}
.f_widget.about-widget .f_list li:last-child {
  margin-bottom: 0px;
}
.f_widget.about-widget .f_list li {
  margin-bottom: 15px;
}
.f_widget.about-widget .f_list {
  margin-bottom: 0px;
}
.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 24px;
}
.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  color: #858da8;
  display: inline-block;
  background: #ebeef5;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.ti-facebook:before {
  content: "\e741";
}
.ti-twitter-alt:before {
  content: "\e74b";
}
.ti-vimeo-alt:before {
  content: "\e74a";
}
.ti-pinterest:before {
  content: "\e731";
}

.btn_get_two {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.btn_get_two:hover {
  background: transparent;
  color: #263b5e;
}

.new_footer_top .f_social_icon a:hover {
  background: #100fb1;
  border-color: #100fb1;
color:white;
}
.new_footer_top .f_social_icon a + a {
  margin-left: 4px;
}
.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #263b5e;
}
.f_600 {
  font-weight: 600;
}
.f_size_18 {
  font-size: 18px;
}
/* h1, h2, h3, h4, h5, h6 {
  color: #4b505e;
} */
.new_footer_top .f_widget.about-widget .f_list li a {
  color: #6a7695;
}


.new_footer_top .footer_bg {
  position: absolute;
  bottom: 0;
  background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEigB8iI5tb8WSVBuVUGc9UjjB8O0708X7Fdic_4O1LT4CmLHoiwhanLXiRhe82yw0R7LgACQ2IhZaTY0hhmGi0gYp_Ynb49CVzfmXtYHUVKgXXpWvJ_oYT8cB4vzsnJLe3iCwuzj-w6PeYq_JaHmy_CoGoa6nw0FBo-2xLdOPvsLTh_fmYH2xhkaZ-OGQ/s16000/footer_bg.png") no-repeat scroll center 0;
  width: 100%;
  height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
  background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEia0PYPxwT5ifToyP3SNZeQWfJEWrUENYA5IXM6sN5vLwAKvaJS1pQVu8mOFFUa_ET4JuHNTFAxKURFerJYHDUWXLXl1vDofYXuij45JZelYOjEFoCOn7E6Vxu0fwV7ACPzArcno1rYuVxGB7JY6G7__e4_KZW4lTYIaHSLVaVLzklZBLZnQw047oq5-Q/s16000/volks.gif") no-repeat center center;
  width: 330px;
  height: 105px;
background-size:100%;
  position: absolute;
  bottom: 0;
  left: 30%;
  -webkit-animation: myfirst 22s linear infinite;
  animation: myfirst 22s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
  background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhyLGwEUVwPK6Vi8xXMymsc-ZXVwLWyXhogZxbcXQYSY55REw_0D4VTQnsVzCrL7nsyjd0P7RVOI5NKJbQ75koZIalD8mqbMquP20fL3DxsWngKkOLOzoOf9sMuxlbyfkIBTsDw5WFUj-YJiI50yzgVjF8cZPHhEjkOP_PRTQXDHEq8AyWpBiJdN9SfQA/s16000/cyclist.gif") no-repeat center center;
  width: 88px;
  height: 100px;
background-size:100%;
  bottom: 0;
  left: 38%;
  position: absolute;
  -webkit-animation: myfirst 30s linear infinite;
  animation: myfirst 30s linear infinite;
}



@-moz-keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}

@-webkit-keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}

@keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}

/*************footer End*****************/