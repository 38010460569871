@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.html {
  font-size: 62.5px;
  background-color: ghostwhite;
  padding: 3% 0;
}

#technology-heading {
  font-size: 2.8rem;
  font-weight: 700;
  padding: 0 20%;
  margin-left: 20px;
  text-align: center;
}

.containers-swipe {
  height: fit-content;
  margin: 0 1%;
}


#tranding  .tranding-slider{
  height: 27rem; /* Fixed height for trending slider on medium screens */
  padding: 1rem 0; /* Padding around trending slider */
  position: relative; /* Relative positioning */
  display: flex; /* Use flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}



.tranding-slide {
  width: 15rem; /* Fixed width for each slide */
  height: 16rem; /* Fixed height for each slide */
  position: relative; /* Relative positioning */
  background: var(--white); /* White background */
  border-radius: 2rem; /* Rounded corners */
  overflow: hidden; /* Hide overflowing content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  display: flex; /* Flex container */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  margin: 1rem; /* Margin around each slide */
}

.tranding-slide:hover {
  transform: scale(1.05); /* Scale up on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Larger shadow on hover */
}


.tranding-slide .tranding-slide-img {
  width: 100%; /* Full width for image */
  height: 100%; /* Full height for image */
  display: flex; /* Flex container */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.tranding-slide .tranding-slide-img img {
  width: 70%; /* Image width */
  height: 70%; /* Image height */
  border-radius: 1rem; /* Rounded corners for image */
  object-fit: contain; /* Preserve aspect ratio */
}

.tranding-slide .tranding-slide-content {
  position: absolute; /* Absolute positioning */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.tranding-slide-content .tranding-slide-content-bottom {
  position: absolute; /* Absolute positioning */
  bottom: 1rem; /* Bottom spacing */
  left: 30%; /* Left offset */
  color: black; /* Text color */
  font-weight: 800; /* Bold font weight */
  font-size: 18px; /* Font size */
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: black; /* Active bullet background */
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none; /* Hide swiper shadows */
}

.tranding-slider-control {
  position: relative; /* Relative positioning */
  display: flex; /* Flex container */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  top: 30px; /* Top spacing */
}

.tranding-slider-control .swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(173, 174, 176, 0.1)); /* Shadow for pagination bullets */
}

.tranding-slider-control .swiper-pagination .swiper-pagination-bullet-active {
  background: black; /* Active bullet background */
}

.food-name-h2 {
  text-align: center; /* Center align text */
  margin-left: 100%; /* Reset margin left */
  margin-right: auto; /* Reset margin right */
  align-items: center; /* Align items */
  max-width: 90%; /* Maximum width for better responsiveness */
}

.food-name-h3 {
  text-align: center; /* Center align text */
  margin-left: 0%; /* Reset margin left */
  margin-right: auto; /* Reset margin right */
  align-items: center; /* Align items */
  width: 100px; /* Width */
  max-width: 100%; /* Maximum width for better responsiveness */
}




/* teblet view */

@media screen and (max-width : 768px) {
  #technology-heading{
    font-size: 2.5rem;
    margin: 0;
    padding: 0;
  }
  .containers-swipe{
    width: 100%;
    overflow: hidden !important;
  }
  .tranding-slide{
    overflow: hidden;
    /* width: 100%; */
  }
}


/* mobile */
@media screen and (max-width : 450px) {
  #technology-heading{
    font-size: 2rem;
  }
  
  .tranding-slide{
    overflow: hidden;
    width: 90%;
    height: 18rem;
    margin: 0 10%;
  }
  .food-name-h3{
    text-align: center; /* Center align text */
  margin-left: 15%; /* Reset margin left */
  margin-right: auto; /* Reset margin right */
  align-items: center; /* Align items */
  width: 100px; /* Width */
  /* max-width: 100%;   */
  }
  
}